
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/equipmenttest";
import { parseTime } from "@/utils";

@Options({})
export default class extends Vue {
  date: any = "";
  situation: any = null;
  private show1 = false;
  private show2 = false;
  private refreshing = false;
  private loading = false;
  private finished = false;
  private tableData: any[] = [];
  private searchForm = {
    dirId: "",
    level: null,
    deviceTime: "",
    deviceNo: "",
    pageSize: 10,
    pageNum: 1,
    total: 0,
  };
  private resultPickerscolumns: any[] = [
    {
      id: 1,
      name: "一般告警",
    },
    {
      id: 2,
      name: "严重告警",
    },
    {
      id: 3,
      name: "紧急告警",
    },
  ];

  mounted() {
    this.searchForm.deviceNo = this.$route.query.id as any;
    this.searchForm.dirId = this.$store.state.project.projectinfoDetail.id;
  }
  openPopup1() {
    this.show1 = true;
  }
  fConfirmDate(d: Date) {
    this.date = parseTime(d, "{y}-{m}-{d}");
    this.searchForm.deviceTime = this.date;
    this.show1 = false;
    this.refreshing = true;
    this.onRefresh();
  }
  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }
  resultPickersonConfirm(val: any) {
    this.situation = val.name;
    this.searchForm.level = val.id;
    this.show2 = false;
    this.refreshing = true;
    this.onRefresh();
  }
  openPopup2() {
    this.show2 = true;
  }
  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.tableData = [];
        this.searchForm.pageNum = 1;
        this.refreshing = false;
      }
      api.pageElevatorAlertRecord(this.searchForm).then((res: any) => {
        this.tableData = [...this.tableData, ...res.data.records];
        this.searchForm.pageNum++;
        this.loading = false;
        if (this.tableData.length >= res.data.total) {
          this.finished = true;
        }
      });
    }, 1000);
  }
}
